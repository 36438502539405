import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import clipboard from "clipboardy";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  copyTextToClipboard,
  uniqueGuid,
  uniqueString,
  useDocumentTitle,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const getTodaysDateFormatted = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(now.getDate()).padStart(2, "0");
  const hours = "00";
  const minutes = "00";
  const seconds = "00";
  const milliseconds = "000";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const getEndDateFormatted = () => {
  const now = new Date();
  now.setDate(now.getDate() + 5); // Add 5 days
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(now.getDate()).padStart(2, "0");
  const hours = "16"; // noon EST
  const minutes = "00";
  const seconds = "00";
  const milliseconds = "000";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const MarketplaceForm = ({ password, fetchMarketplaceItems }) => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    price: 25,
    totalQuantity: 1,
    totalRemaining: 1,
    dateCreated: getTodaysDateFormatted(),
    imageUrl: "",
    description: "",
    itemType: 1,
    inUse: "",
    testMode: "",
    paymentType: 0,
    gated: "",
    // auctions & drawings
    endDate: getEndDateFormatted(),
    // drawings
    maxQuantity: 2,
    quantityOfWinners: 3,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getButtonDisabledClass() === "disabled") {
      return;
    }

    // fill out the missing data
    const options = JSON.parse(JSON.stringify(formData));
    options.id = uniqueGuid();
    options.totalRemaining = options.totalQuantity;
    options.dateCreated = getTodaysDateFormatted();
    options.inUse = "True";
    options.testMode = "False";

    // nullify unused fields
    // unused for buy-it-nows
    if (parseInt(options.itemType) === 0) {
      delete options.endDate;
    }
    // only used for drawings
    if (parseInt(options.itemType) !== 2) {
      delete options.maxQuantity;
      delete options.quantityOfWinners;
    }

    const api = new ApiService({ password });
    const { success, error, info } = await api.post(
      `rsop/marketplace/createItem`,
      {
        options,
      }
    );
    if (!success && !info) {
      toast.error(`An error occurred: ${error}`);
      return false;
    } else if (success) {
      toast.success(`${success}`);
      // refresh marketplace items
      fetchMarketplaceItems();
      return true;
    } else if (info) {
      toast(`${info}`);
      return true;
    }
  };

  const getButtonDisabledClass = () => {
    let isDisabled = true;
    const {
      id,
      name,
      price,
      totalQuantity,
      totalRemaining,
      dateCreated,
      imageUrl,
      description,
      itemType,
      inUse,
      testMode,
      paymentType,
      gated,
      // auctions & drawings
      endDate,
      // drawings
      maxQuantity,
      quantityOfWinners,
    } = formData;
    try {
      if (
        name.length &&
        price > 0 &&
        totalQuantity > 0 &&
        imageUrl.length &&
        description.length &&
        !isNaN(itemType) &&
        !isNaN(paymentType)
      ) {
        isDisabled = false;
      }
    } catch (error) {
      console.log(`error`, error);
      isDisabled = true;
    }
    return isDisabled ? "disabled" : "";
  };

  return (
    <div className="marketplace-container">
      <div className="marketplace-form-container">
        <form onSubmit={handleSubmit}>
          {/*<div className="mb-3">
            <label htmlFor="id" className="form-label">
              ID
            </label>
            <input
              type="text"
              className="form-control"
              id="id"
              name="id"
              value={formData.id}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="price" className="form-label">
              Price (or starting bid for auctions)
            </label>
            <input
              type="number"
              className="form-control"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="totalQuantity" className="form-label">
              Total Quantity (for auctions use 1)
            </label>
            <input
              type="number"
              className="form-control"
              id="totalQuantity"
              name="totalQuantity"
              value={formData.totalQuantity}
              onChange={handleChange}
            />
          </div>
          {/* <div className="mb-3">
            <label htmlFor="totalRemaining" className="form-label">
              Total Remaining
            </label>
            <input
              type="number"
              className="form-control"
              id="totalRemaining"
              name="totalRemaining"
              value={formData.totalRemaining}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-3">
            <label htmlFor="imageUrl" className="form-label">
              Image URL<br></br>
              If you dont have a URL: Upload image to a site like{" "}
              <a href="https://imgur.com/" target="_blank">
                Imgur
              </a>
              , right click on image and open in new tab, copy and paste URL
              here (URL should end in .jpg or .png) <br></br>
              <i>
                Images should be square dimensions or close to it for best
                results
              </i>
            </label>
            <input
              type="text"
              className="form-control"
              id="imageUrl"
              name="imageUrl"
              value={formData.imageUrl}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              rows="4"
              onChange={handleChange}
              style={{ height: "65px" }}
            ></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="itemType" className="form-label">
              Item Type
              <br></br>
              <span className="rmono">
                <b>0</b> - Buy It Now<br></br>
                <b>1</b> - Auction<br></br>
                <b>2</b> - Drawing<br></br>
              </span>
            </label>
            <input
              type="number"
              className="form-control"
              id="itemType"
              name="itemType"
              value={formData.itemType}
              onChange={handleChange}
            />
          </div>
          {/* <div className="mb-3">
            <label htmlFor="inUse" className="form-label">
              In Use
            </label>
            <input
              type="checkbox"
              className="form-check-input"
              id="inUse"
              name="inUse"
              checked={formData.inUse}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="testMode" className="form-label">
              Test Mode
            </label>
            <input
              type="checkbox"
              className="form-check-input"
              id="testMode"
              name="testMode"
              checked={formData.testMode}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-3">
            <label htmlFor="paymentType" className="form-label">
              Payment Type
              <br></br>
              <span className="rmono">
                <b>0</b> - Royal<br></br>
                <b>1</b> - RSOP Chip (quantity - e.g. if price is 3 then you
                need to trade in 3 RSOP Chip NFTs)<br></br>
                <b>2</b> - RSOP Chip (value - e.g. if price is 1000 then you
                need to trade in Chips with values summing to at least 1000)
                <br></br>
                <b>3</b> - RSOP Card (quantity)<br></br>
                <b>4</b> - Royal Art Rewards (quantity)
              </span>
            </label>
            <input
              type="number"
              className="form-control"
              id="paymentType"
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="gated" className="form-label">
              Gates<br></br>
              Options:{" "}
              <span className="rmono">
                Joker, Eternal, RoyalFlush, Stamp, 555
              </span>
              <br></br>To gate for multiple, separate with a comma -e.g. "Joker,
              RoyalFlush" will only allow users with either a Joker or a
              RoyalFlush to purchase
            </label>
            <input
              type="text"
              className="form-control"
              id="gated"
              name="gated"
              value={formData.gated}
              onChange={handleChange}
            />
          </div>
          {parseInt(formData.itemType) === 1 ||
          parseInt(formData.itemType) === 2 ? (
            <div className="mb-3">
              <label htmlFor="endDate" className="form-label">
                End Date (UTC) (Auctions and Drawings only)
              </label>
              <input
                type="text"
                className="form-control"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
            </div>
          ) : null}
          {parseInt(formData.itemType) === 2 ? (
            <>
              <div className="mb-3">
                <label htmlFor="maxQuantity" className="form-label">
                  Max Quantity Per Person (Drawings only)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="maxQuantity"
                  name="maxQuantity"
                  value={formData.maxQuantity}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="quantityOfWinners" className="form-label">
                  Number of Winners (Drawings only)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="quantityOfWinners"
                  name="quantityOfWinners"
                  value={formData.quantityOfWinners}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : null}
          <button
            type="submit"
            className={`btn btn-primary ${getButtonDisabledClass()}`}
          >
            Create Item
          </button>
        </form>
      </div>
    </div>
  );
};

const MarketplaceItemsTable = ({
  password,
  fetchMarketplaceItems,
  marketplaceItems,
}) => {
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const selectItems = (itemsToSelect) => {
    const newSelectedItemIds = [...selectedItemIds];
    itemsToSelect.forEach((item) => {
      if (!newSelectedItemIds.includes(item.id)) {
        newSelectedItemIds.push(item.id);
      }
    });
    setSelectedItemIds(newSelectedItemIds);
  };

  const unselectItems = (itemsToUnselect) => {
    const itemIdsToUnselect = itemsToUnselect.map((r) => r.id);
    setSelectedItemIds((prevSelectedItemIds) => {
      return prevSelectedItemIds.filter((r) => !itemIdsToUnselect.includes(r));
    });
  };

  const toggleSelectAllItems = (event) => {
    console.log(`toggleSelectAllItems() - event.target`, event.target);
    const checked = event.target.checked;
    // select all items on this page
    if (checked) {
      selectItems(marketplaceItems);
    }
    // unselect all items on this page
    else {
      unselectItems(marketplaceItems);
    }
  };
  const toggleSelectItem = (event, item) => {
    const checked = event.target.checked;
    console.log(`toggleSelectItem() - checked`, checked);
    if (checked) {
      selectItems([item]);
    } else {
      unselectItems([item]);
    }
  };

  const getSelectedItems = () => {
    return marketplaceItems.filter((i) => selectedItemIds.includes(i.id));
  };

  const handleGenerateMarketplaceItemsSql = () => {
    if (selectedItemIds.length) {
      const items = getSelectedItems();
      const sql = generateInsertItemsQuery(items);
      // Copy the SQL query to the clipboard
      clipboard.write(sql);
      toast.success("SQL query copied to clipboard");
    }
  };

  const handleGenerateBottleImages = async () => {
    if (selectedItemIds.length) {
      const items = getSelectedItems();
      try {
        const isLocalhost =
          window.location.hostname === "localhost" ||
          window.location.hostname === "127.0.0.1";
        const apiUrl = isLocalhost
          ? `https://localhost:7775/api/`
          : `https://royalsociety.io:7775/api/`;
        const api = new ApiService({ apiUrl, password });
        const data = await api.post(
          `marketplace/generateBottles`,
          {
            items,
          },
          "arraybuffer" // expect binary data (.zip)
        );
        if (data) {
          const blob = new Blob([data], { type: "application/zip" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "marketplace-bottles.zip";
          document.body.appendChild(a);
          a.click();
          a.remove();
          toast.success(`Downloaded marketplace-bottles.zip`);
        }
      } catch (error) {
        console.error(error);
        toast.error(`${error}`);
        return false;
      }
    }
  };

  const handleDeleteItems = async () => {
    if (selectedItemIds.length > 0) {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete items?"
      );
      if (!userConfirmed) {
        return;
      }
      try {
        const api = new ApiService({ password });
        const { success, error } = await api.post(
          `rsop/marketplace/deleteItems`,
          {
            itemIds: selectedItemIds,
          }
        );
        if (!success) {
          toast.error(`${error}`);
          return false;
        } else {
          toast.success(`${success}`);
          setSelectedItemIds([]);
          fetchMarketplaceItems();
          return true;
        }
      } catch (error) {
        console.error(error);
        toast.error(`${error}`);
        return false;
      }
    }
  };

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  return (
    <>
      <div class="outputHeader">Marketplace Items</div>
      <div className="dev-checklist">
        <div className="title">Dev Checklist:</div>
        <ul>
          <li>• Generate SQL and add to database</li>
          <li>• Add Drawing NFT metadata to database</li>
          <li>• Generate Bottle images and copy to client repo</li>
          <li>• Update Client ItemType Placement Ratios</li>
          <li>
            • Call setCurrentTokenId on{" "}
            <a
              href="https://polygonscan.com/address/0xb159f1a0920a7f1d336397a52d92da94b1279838#writeContract"
              target="_blank"
            >
              Polygon contract
            </a>
          </li>
          <li>
            • Update Backend Constants.RSOP_DRAWINGS_CURRENT_TOKEN_ID and
            RSOP_DRAWINGS_ITEM_IDS_TOKEN_IDS with the Drawing ID
          </li>
          <li>• Push to Git and pull updates to server</li>
          <li>• Test</li>
        </ul>
      </div>
      <div className="outputBody">
        <div className="table-wrapper">
          <div className="buttons-row">
            <button
              className={`sign-up-btn btn btn-sm ${
                selectedItemIds.length === 0 ? "btn-grey" : "btn-primary"
              }`}
              onClick={handleGenerateMarketplaceItemsSql}
            >
              {`Generate SQL`}
            </button>
            <button
              className={`sign-up-btn btn btn-sm ${
                selectedItemIds.length === 0 ? "btn-grey" : "btn-primary"
              }`}
              onClick={handleGenerateBottleImages}
            >
              {`Generate Bottle Images`}
            </button>
            <button
              className={`sign-up-btn btn del btn-sm ${
                selectedItemIds.length === 0 ? "btn-grey" : "btn-primary"
              }`}
              onClick={handleDeleteItems}
            >
              {`Delete Items`}
            </button>
          </div>
          <Table responsive className="header-border ">
            <thead>
              <tr>
                <th className="width50 ">
                  <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkAll"
                      required
                      defaultChecked={false}
                      checked={
                        !marketplaceItems.some(
                          (item) => !selectedItemIds.includes(item.id)
                        )
                      }
                      onChange={toggleSelectAllItems}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkAll"
                    ></label>
                  </div>
                </th>
                <th>Id</th>
                <th>Image</th>
                <th>Name</th>
                <th>Item Type</th>
                <th>Price</th>
                <th>Total Quantity</th>
                <th>End Date</th>
                <th>Description</th>
                <th>Payment Type</th>
                <th>Gated</th>
                <th>Max Quantity</th>
                <th>Number of Winners</th>
              </tr>
            </thead>
            <tbody>
              {marketplaceItems.map((item, i) => {
                const {
                  id,
                  name,
                  price,
                  totalQuantity,
                  totalRemaining,
                  dateCreated,
                  endDate,
                  maxQuantity,
                  quantityOfWinners,
                  imageUrl,
                  description,
                  itemType,
                  inUse,
                  testMode,
                  paymentType,
                  gated,
                } = item;
                return (
                  <tr key={`tr_row_${i}`}>
                    <td>
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox2"
                          required
                          defaultChecked={false}
                          checked={selectedItemIds.includes(item.id)}
                          onChange={(event) => toggleSelectItem(event, item)}
                        />
                      </div>
                    </td>
                    <td>
                      {id.substring(0, 3)}
                      {"... "}
                      <span
                        className="clipboard-icon hvr-shrink"
                        onClick={() => copyText(id, "Item ID")}
                      >
                        <FileCopyOutlinedIcon
                          style={{
                            width: "0.66em",
                            height: "0.66em",
                          }}
                        />
                      </span>
                    </td>
                    <td>
                      <div className="img-container">
                        <div className="item-img">
                          <img src={imageUrl} className="img-fluid" />
                        </div>
                        <div
                          className="clipboard-icon image hvr-shrink"
                          onClick={() => copyText(imageUrl, "Image URL")}
                        >
                          <FileCopyOutlinedIcon
                            style={{
                              width: "0.66em",
                              height: "0.66em",
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>{name}</td>
                    <td>{itemType}</td>
                    <td>{price}</td>
                    <td>{totalQuantity}</td>
                    <td>{endDate}</td>
                    <td>{description}</td>
                    <td>{paymentType}</td>
                    <td>{gated}</td>
                    <td>{maxQuantity}</td>
                    <td>{quantityOfWinners}</td>
                    {/* <td>{inUse ? "Yes" : "No"}</td>
                    <td>{testMode ? "Yes" : "No"}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
function generateInsertItemsQuery(items) {
  // MarketplaceItems
  const itemsStrings = items.map((item) => {
    const {
      id,
      name,
      price,
      totalQuantity,
      totalRemaining,
      dateCreated,
      imageUrl,
      description,
      itemType,
      inUse,
      testMode,
      paymentType,
      gated,
    } = item;
    // the hardcoded False is testMode override
    return `('${id}', 
      '${name.replace(/'/g, "''")}', 
      ${price}, 
      ${totalQuantity}, 
      ${totalRemaining}, 
      '${dateCreated}', 
      '${imageUrl}', 
      '${description.replace(/'/g, "''")}', 
      ${itemType}, 
      '${inUse}', 
      'False', 
      ${paymentType}, 
      ${gated ? `'${gated.replace(/'/g, "''")}'` : "NULL"})`;
  });
  const marketplaceItemsInsert = `INSERT INTO [dbo].[MarketplaceItems]
    ([Id], [Name], [Price], [TotalQuantity], [TotalRemaining], [DateCreated], [ImageUrl], [Description], [ItemType], [InUse], [TestMode], [PaymentType], [Gated])
    VALUES
    ${itemsStrings.join(",\n")}`;

  // AuctionList table
  const auctionListStrings = items
    .filter((i) => parseInt(i.itemType) === 1)
    .map((item) => {
      const { id, price, dateCreated, endDate } = item;

      return `('${uniqueGuid()}', 
        '${id}', 
        NULL, 
        ${price}, 
        '${dateCreated}', 
        '${endDate}', 
        '${endDate}', 
        'False', 
        NULL)`;
    });
  let auctionListInsert = ``;
  if (auctionListStrings.length) {
    auctionListInsert = `
    
    INSERT INTO [dbo].[AuctionList]
      ([Id], [MarketplaceItemId], [CurrentWinnerAccountId], [CurrentBid], [StartDate], [OriginalEndDate], [ActualEndDate], [HasEnded], [ActualWinnerAccountId])
      VALUES
      ${auctionListStrings.join(",\n")}`;
  }

  // DrawingList table
  const drawingListStrings = items
    .filter((i) => parseInt(i.itemType) === 2)
    .map((item) => {
      const { id, dateCreated, endDate, quantityOfWinners, maxQuantity } = item;

      return `('${uniqueGuid()}', 
        '${id}', 
        ${quantityOfWinners}, 
        ${maxQuantity}, 
        '${dateCreated}', 
        '${endDate}', 
        'False') `;
    });
  let drawingListInsert = ``;
  if (drawingListStrings.length) {
    drawingListInsert = `
    
    INSERT INTO [dbo].[DrawingList]
      ([Id], [MarketplaceItemId], [QuantityOfWinners], [MaxQuantity], [StartDate], [EndDate], [HasEnded])
      VALUES
      ${drawingListStrings.join(",\n")}`;
  }

  const prefix = `USE [RSOPMetaverseDB]
    GO
    
    `;

  const postfix = `
    GO`;
  return (
    prefix +
    marketplaceItemsInsert +
    auctionListInsert +
    drawingListInsert +
    postfix
  );
}

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [marketplaceItems, setMarketplaceItems] = useState([]);

  async function fetchMarketplaceItems() {
    const api = new ApiService({ password });
    try {
      const response = await api.get(`rsop/marketplace/getAll`);
      setMarketplaceItems(response);
      console.log("marketplaceItems", response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchMarketplaceItems();
  }, []);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("Marketplace Dashboard - RSOP");

  return (
    <Fragment>
      <div className="global-dash-wrapper marketplace-wrapper">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        <PageHeaderBar
          pages={[
            { title: "RSOP", url: `` },
            {
              title: "Marketplace Dashboard",
              url: `/rsop/marketplace/dashboard`,
            },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
              <Card className="mb-3 h-fc bot-table">
                <div className="row">
                  <div className="outputHeader">Add Marketplace Item</div>
                </div>
                <MarketplaceForm
                  password={password}
                  fetchMarketplaceItems={fetchMarketplaceItems}
                />
              </Card>
            </motion.div>
            <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
              <Card className="mb-3 h-fc bot-table">
                <div className="row">
                  <MarketplaceItemsTable
                    password={password}
                    marketplaceItems={marketplaceItems}
                    fetchMarketplaceItems={fetchMarketplaceItems}
                  />
                </div>
              </Card>
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
