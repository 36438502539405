import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; //Example style, you can use another
import toast from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";
import { Card, Table, Badge, Tooltip, OverlayTrigger } from "react-bootstrap";

import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import "react-datepicker/dist/react-datepicker.css";

import { ExportJsonCsv } from "react-export-json-csv";
import { Box, Button } from "@mui/material";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  arraysAreEqual,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import AddEditDynamicEvent from "./AddEditDynamicEvent";

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const OwnerEmailPrompt = ({
  eventId,
  submitOwnerAssignment,
  setShowEmailEventId,
}) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await submitOwnerAssignment(eventId, email);
    if (result) {
      // reset and hide the email input on success
      setEmail("");
      setShowEmailEventId("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        onChange={handleInputChange}
        type="email"
        className="form-control w-100 mt-1"
        placeholder="Owner Email"
        value={email}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc"
        onClick={(e) => handleSubmit(e)}
      >
        Add
      </div>
    </form>
  );
};

// Change name
const OwnerAssign = ({ password }) => {
  const [events, setEvents] = useState([]);
  const [showEmailEventId, setShowEmailEventId] = useState("");

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const eventsResponse = await api.get(
        `landmark/dynamicevents/getAllEvents`
      );
      console.log(`OwnerAssign - events`, eventsResponse);
      setEvents(eventsResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  async function submitOwnerAssignment(eventId, email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/dynamicevents/assignOwnerToEvent/`,
        {
          eventId,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh owners
        getEvents();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function removeOwnerAssignment(eventId, email) {
    if (!eventId || !email) {
      toast.error("Error occurred");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/dynamicevents/removeOwnerFromEvent/`,
        {
          eventId,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
      } else {
        toast.success(`${success}`);
        // refresh owners
        getEvents();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (!events || !events.length) {
    return <div>Loading Events...</div>;
  }

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <Card className="mb-3 p-3 h-fc bot-table">
        <div className="row">
          <div className="outputHeader">Event Owners</div>
        </div>
        <div className="events-owners-subheading">
          Owners assigned to events will receive email notifications when new
          event RSVPs are detected
        </div>
        <div className="event-owners-wrapper">
          {events.map((event) => {
            return (
              <div key={event.eventId}>
                <div className="event-owner-container">
                  <img src={event.imageUrl} className="img-fluid event-img" />
                  <div className="event-title">{event.title}</div>
                  {event.owners ? (
                    <>
                      {event.owners.map((o, i) => (
                        <div className="event-owner-outer" key={i}>
                          <div className="event-owner">{o.email}</div>
                          <i
                            onClick={() =>
                              removeOwnerAssignment(event.eventId, o.email)
                            }
                            className="event-owner-remove fa-sharp fa-solid fa-delete-left ml-05"
                          ></i>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {showEmailEventId === event.eventId ? (
                    <motion.div
                      animate={{ opacity: 1 }}
                      initial={{ opacity: 0 }}
                    >
                      <OwnerEmailPrompt
                        eventId={event.eventId}
                        submitOwnerAssignment={submitOwnerAssignment}
                        setShowEmailEventId={setShowEmailEventId}
                      />
                    </motion.div>
                  ) : (
                    <i
                      onClick={() => setShowEmailEventId(event.eventId)}
                      className="event-owner-add fa-sharp fa-solid fa-plus ml-05"
                    ></i>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </motion.div>

    // <Box>
    //   Assign Event
    //   <Select
    //     options={eventsOptions}
    //     onChange={(values) => setSelectedEventId(values)}
    //     color="#fff"
    //     styles={{ backgroundColor: "#000" }}
    //   ></Select>
    //   <input className="form-control" onKeyUp={changeInputText}></input>
    //   <Button className="form-control" onClick={submitOwnerAssignment}>
    //     Submit
    //   </Button>
    // </Box>
  );
};

const SelectEvent = ({ events, password, selectedEvent }) => {
  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`${desc} copied to clipboard`);
  };

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">VIP Dinner Events</div>
            <>
              <div className="events-owners-subheading">
                <a
                  // className="blue-link"
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/1MC0b2J3mWG5r-RyEsl2TqaQWgc4o2Wem66ZOJlkB5pg/edit?gid=138896324#gid=138896324"
                >
                  <div className="create-event-btn btn btn-sm btn-success btn-view-rsvps">
                    View RSVPs for all events
                  </div>
                </a>
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {events && events.length
                  ? events.map((event) => {
                      const selectedClass =
                        selectedEvent && event.eventId === selectedEvent.eventId
                          ? "selected"
                          : "";
                      return (
                        <div key={event.eventId}>
                          <div className={`event-owner-container`}>
                            <img
                              src={event.imageUrl}
                              className="img-fluid event-img"
                            />
                            <div className="event-title">
                              <b>{event.title}</b>
                            </div>
                            <div className="event-title">
                              <a
                                className="blue-link"
                                target="_blank"
                                href={`https://events.landmarkventures.com/${event.pageUrl}`}
                              >
                                {`https://events.landmarkventures.com/${event.pageUrl}`}
                              </a>{" "}
                              <span
                                className="clipboard-icon hvr-shrink"
                                onClick={() =>
                                  copyText(
                                    `https://events.landmarkventures.com/${event.pageUrl}`,
                                    "URL"
                                  )
                                }
                              >
                                <FileCopyOutlinedIcon
                                  style={{
                                    width: "0.7em",
                                    height: "0.7em",
                                  }}
                                />
                              </span>
                            </div>
                            {event.referralUrls && event.referralUrls.length ? (
                              <>
                                <div className="ref-urls-title">
                                  Referral URLs:
                                </div>
                                <div className="ref-urls-container">
                                  {event.referralUrls.map((r) => (
                                    <div className="ref-inner-container">
                                      <span className="ref-urls-name">
                                        {r.name}:
                                      </span>
                                      <a
                                        className="blue-link ref-urls-url"
                                        target="_blank"
                                        href={`https://events.landmarkventures.com/${event.pageUrl}/${r.url}`}
                                      >{`https://events.landmarkventures.com/${event.pageUrl}/${r.url}`}</a>{" "}
                                      <span
                                        className="clipboard-icon hvr-shrink"
                                        onClick={() =>
                                          copyText(
                                            `https://events.landmarkventures.com/${event.pageUrl}/${r.url}`,
                                            "URL"
                                          )
                                        }
                                      >
                                        <FileCopyOutlinedIcon
                                          style={{
                                            width: "0.7em",
                                            height: "0.7em",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees
              .sort((a, b) => {
                // If both have 'lastSeenTime', compare by 'lastSeenTime' (descending)
                if (a.lastSeenTime && b.lastSeenTime) {
                  return b.lastSeenTime - a.lastSeenTime;
                }

                // If one has 'lastSeenTime', prioritize that one
                if (a.lastSeenTime && !b.lastSeenTime) {
                  return -1;
                }
                if (!a.lastSeenTime && b.lastSeenTime) {
                  return 1;
                }

                // If neither have 'lastSeenTime', compare by 'firstName' alphabetically
                return a.firstName.localeCompare(b.firstName);
              })
              .map((employee, i) => (
                <EmployeeInfo
                  key={i}
                  landmarkEmployee={employee}
                  handleOnClick={signInAsEmployee}
                />
              ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState(null);

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const eventsResponse = await api.get(
        `landmark/dynamicevents/getAllEvents`
      );
      console.log(`Dashboard - getEvents()`, eventsResponse);
      setEvents(eventsResponse);
      setSelectedEvent(null);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (isAuthenticated && isSignedIn) {
      getEvents();
    }
  }, [isSignedIn, isAuthenticated]);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  const signInAsEmployee = (employee) => {
    if (!employee) {
      toast.error("Cannot sign in as employee");
      return;
    }
    setIsSignedIn(true);
    setEmployee(employee);

    try {
      const api = new ApiService({ employee });
      // sets lastSeenTime
      api.get(`landmark/employees/login`);
    } catch (error) {
      console.error(`signInAsEmployee() - employee login error`, error);
    }

    toast.success(`Logged in as ${employee.firstName} ${employee.lastName}`);
  };
  useDocumentTitle("VIP Dinner Events Dashboard - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper dynamic-events">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        {isSignedIn && (
          <div className="landmark-sign-in" style={{ marginBottom: "18px" }}>
            <div className="existing-accounts-container">
              <EmployeeInfo landmarkEmployee={employee} disableHover={true} />
            </div>
          </div>
        )}
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            {
              title: "VIP Dinner Events Dashboard",
              url: `/landmark/vipdinner/dashboard`,
            },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated && isSignedIn ? (
          <>
            <AddEditDynamicEvent
              events={events}
              getEvents={getEvents}
              password={password}
              employee={employee}
            />
            {/* <OwnerAssign password={password} employee={employee} /> */}
            <SelectEvent
              events={events}
              getEvents={getEvents}
              password={password}
              employee={employee}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
            {/* {selectedEvent && (
              <MasterEventsTable
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                password={password}
                employee={employee}
              />
            )} */}
          </>
        ) : null}
        {/* show sign in */}
        {isAuthenticated && !isSignedIn ? (
          <>
            <motion.div
              animate={{
                scale: [0, 1.04, 1],
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <EmployeeSignIn
                password={password}
                signInAsEmployee={signInAsEmployee}
              />
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
